import React, { memo } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"
const StuckPixelFix = ({ startRepair }) => {
  return (
    <div className="px-3 prose-sm prose sm:prose lg:prose-lg xl:prose-xl md:px-0">
      <p className="lead">
        Klicken Sie auf die Schaltfläche <code>Reparieren</code>. Ihr Bildschirm
        wird schwarz und Sie sehen ein Quadrat mit Verläufen. Ziehen Sie das
        Quadrat und bringen Sie es über Ihre feststeckenden Pixel. Warten Sie 10
        bis 15 Minuten.
      </p>
      <blockquote>
        Vergessen Sie nicht, Ihre Energieeinstellungen so anzupassen, dass sich
        der Bildschirm nicht ausschaltet.
      </blockquote>
      <p>
        Sie können{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        drücken, um den Reparaturprozess für feststeckende Pixel abzuschließen
        und den Vorgang zu beenden.
      </p>
      <div className="text-center">
        <PurpleSolidButton text="Reparieren" onClick={startRepair} />
      </div>
    </div>
  )
}
export default memo(StuckPixelFix)
